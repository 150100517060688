<template>
  <div class="integralHtml">
    <div>
      <div class="integral"
        v-for="item in list"
        :key="item">
        <div class="integralName">
          <span>姓名: {{item.AddMan}}</span>
          <!-- <span>电话: {{item.Mobile}}</span> -->
        </div>
        <div class="integralParty"
          style="color:black;">巡防街道: {{item.SNames}}</div>
        <div class="integralParty">添加时间: {{item.AddTime.replace('T', ' ')}}</div>
        <div style="text-align: right;padding:0 10px 10px 10px">
          <van-button type="info"
            round
            style="width:80px;"
            @click="AreaRangeClick(item)"
            size="mini">巡街范围</van-button>
        </div>
      </div>
      <van-empty v-if="zanwuShow"
        style="background: #ffff;"
        description="暂无信息" />
    </div>
  </div>
</template>

<script>
import { getwgToken, setwgToken } from "@/utils/auth";
import { WeGetPatrolareaPage } from "@/api/wangge";
export default {
  data () {
    return {
      active: 0,
      list: [],
      listfrom: {},
      zanwuShow: false
    }
  },
  methods: {
    onClick: function (name, title) {
      console.log(name);
      this.listfrom.status = name
      this.listfrom.page = 1;
      this.list = []
      this.getList()

    },
    // 获取分页列表
    getList () {
      console.log(this.$route.query);
      this.listfrom.accToken = this.$route.query.wgToken;
      WeGetPatrolareaPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = [];
              this.zanwuShow = true
            } else {
              this.list = res.data.data;
              this.zanwuShow = false
            }
          }
        })
        .catch(() => { });
    },
    //寻访区域
    AreaRangeClick (row) {
      var itemmark = JSON.stringify(row);
      // var AreaRange = JSON.stringify(row.AreaRange);
      // var itemmark = row.Color + [];
      this.$router.push({ name: 'trajectory', params: { id: itemmark } })
      // this.$router.push('/watchman/trajectory/' + AreaRange + '/' + row.Color);
    },
  },
  mounted () {
    this.getList();
  },
}
</script>

<style>
.integralHtml {
  /* background-color: rgb(246, 242, 242); */
}
.integral {
  width: 90%;
  margin: 10px 5%;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.integralName {
  padding: 15px 0px 12px 15px;
  font-size: 15px;
  overflow: hidden;
}
.integralName span:nth-child(1) {
  float: left;
}
.integralName span:nth-child(2) {
  float: right;
  margin-right: 15px;
}
.integralParty {
  padding-left: 15px;
  padding-bottom: 15px;
  font-size: 14px;
  color: rgb(172, 167, 167);
}
</style>